import React from "react"
import { graphql } from "gatsby"
import get from "lodash.get"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "@emotion/styled"
import BoxedLayout from "./../components/layouts/BoxedLayout"
import Seo from "../components/seo"
import Divider from "../components/Divider"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { H1 } from "./../components/Headings"

const DatePosted = styled.div`
  display: block;
  margin-bottom: 20px;
  opacity: 0.5;
  font-size: 0.8em;
`

const PostThumbnail = ({ img, alt="" }) => {
  if (!img) {
    return null
  }
  const theImg = getImage(img)
  return (
    <div style={{marginBottom: '20px'}}>
      <GatsbyImage image={theImg} alt={alt} />
    </div>
  )
}

export default function Template(props) {
  const { data, pageContext } = props
  const { body, meta } = pageContext
  const { site } = data
  const featuredImage = get(meta, 'featuredImage')
  const dt = new Date(meta.date)

  const seoProps = {
    title: meta.title,
    description: meta.desc,
    url: site.siteMetadata.url + meta.url
  }
  if (featuredImage) {
    // seoProps.image = fluidFeaturedImg.featuredImage
  }
  
  return (
    <BoxedLayout>

      <Seo {...seoProps} />

      <Divider size={30} />
      <div className="blog-post-container">
        <div className="blog-post">
          <H1>{meta.title}</H1>
          <DatePosted>{dt.toDateString()}</DatePosted>
          <PostThumbnail img={featuredImage} />
          <MDXRenderer>{body}</MDXRenderer>
        </div>
      </div>
    </BoxedLayout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
        url
        image
      }
    }
  }
`