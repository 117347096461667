import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = props => css`
  color: ${props.inverse ? props.theme.inverse.colors.baseTextColor : props.theme.colors.baseTextColor};
`

export const H1 = styled.h1`
  ${dynamicStyle};
  font-size: 2rem;
  margin: 0;
`

export const H2 = styled.h2`
  ${dynamicStyle};
  font-size: 1.8rem;
`

export const H3 = styled.h3`
  ${dynamicStyle};
  font-size: 1.6rem;
`

export const HeadingCaption = styled.span`
  ${dynamicStyle};
`